<template>
  <modal class="eidtPaymentMethodModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">修改付款方式</div>
    <div class="modal-body">
        <div class="tr">
            <el-tooltip effect="dark" :content="data.DeskName" placement="top">
                <span class="text-label inlineBlock nowrap">{{data.DeskName}}</span> 
            </el-tooltip>
            <span class="text-label">桌台，{{data.PaymentName}}{{data.CheckMoney}}</span>
        </div>
        <div class="tr">
            <span class="text-label">结账方式：</span>
            <el-select v-model="payTypeVaule" placeholder="请选择" value-key="item">
                <el-option  v-for="item in payTypeList" :key="item.PAY_ID"  :label="item.PAY_NAME" :value="item.PAY_ID"> </el-option>
            </el-select>
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
        <button class="btn btn-confirm" @click="confirm()">确认</button>
    </div>
  </modal>
</template>
<script>
export default {
    name:'eidtPaymentMethodModel',
    props:{
        isShow:Boolean,
        /**修改付款 传入的数据 */
        data:{
            /**桌台 */
            DeskName:String,
            /**结账金额 */
            CheckMoney:Number,
            /**付款名称 */
            PaymentName:String,
            /**结账方式 */
            PaymentID:String
        }
    },
    data(){
        return {
            /**结账方式数据 */
            payTypeList:[],
            /**选中的 结账方式 */
            payTypeVaule:'',
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.loadData();
        })
    },
    watch:{
        isShow(value){
            if(value==true){
                this.payTypeVaule='';
                if(!this.payTypeList || this.payTypeList.length==0){
                    this.loadData();
                }
            }
        }
    },
    methods:{
        /**加载 结账方式 */
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$cacheData.PayType().then((d)=>{
                loading.close();
                if(d){
                    let all=[]
                    d.forEach(item => {
                        item.PayTypes.forEach((type)=>{
                            if(type.INTERFACE_TYPE==2 || type.INTERFACE_TYPE==1 || type.INTERFACE_TYPE==9021001){
                                all.push(type)
                            }
                        })
                    });
                    this.payTypeList=all;
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('加载结账方式数据失败：'+e,);
                console.log('加载结账方式数据失败：'+e);
            })
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            if(!this.payTypeVaule){
                this.$message.warning('未选择任何结账方式，请先选择！');
                return;
            }
            let data={};
            (this.payTypeList||[]).forEach((item)=>{
                if(item.PAY_ID==this.payTypeVaule){
                    data={PaymentID:item.PAY_ID,PaymentName:item.PAY_NAME};
                }
            })
            this.$emit("confirm",data);
        }
    }
}
</script>

<style lang="scss">
@import "./eidtPaymentMethodModel.scss"
</style>